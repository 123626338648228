@import "@/styles/theme/main.scss";
.card {
  width: 100%;
  min-width: 160px;
  height: 100px;
  position: relative;
  background-color: $background-paper;
  border-radius: $border-radius;
  cursor: pointer;
  transition: all 0.3s;

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    transition: all 0.25s ease-in-out;

    // &--filtered {
    //   filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(281deg)
    //     grayscale(100%) brightness(105%) contrast(103%);
    // }
  }

  &:hover {
    @include lighen(20%);

    .container__card__image {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }
}

.skeleton {
  @include skeleton();
  width: 100%;
  height: 100px;
  min-width: 160px;
}
