@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    height: max-content;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;

    & > * {
      flex: 0 0
        calc(
          (100% - (var(--items-per-view) - 1) * 16px) / var(--items-per-view)
        );
    }

    @include hide-scrollbar;
  }
}
