@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 350px;
  position: fixed;
  bottom: calc(48px + 32px);
  right: 16px;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: $card-color;
  border: 1px solid $divider-color;
  height: calc($box-height * 1.5);
  gap: 16px;
  z-index: 110;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    @include lighen(15%);
  }

  @include media-tablet {
    width: 100dvw;
    max-width: unset;
    bottom: 58px;
    right: 0px;
    left: 0px;
    height: calc($box-height);
    border-bottom: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &--upper {
    bottom: 48px;
  }

  &__image {
    width: 100px;
    height: 100%;
    object-fit: cover;
  }

  &__row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
  }

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__description {
      font-size: 12px;
      font-weight: 400;
      color: $text-secondary;
    }

    &__title {
      font-size: 14px;
      font-weight: 700;
      color: $text-primary;
    }

    &__icon {
      font-size: 18px;
      color: $text-secondary;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: $text-primary;
      }
    }
  }
}
