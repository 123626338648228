@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 24px;

  &--hidden {
    display: none;
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid $divider-color;
  }
}
