@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));

  @include media-tablet {
    grid-template-columns: 1fr;
  }
}
