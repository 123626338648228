@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  padding-top: 80px;
  overflow-x: hidden;
  padding-left: 270px;
  transition: padding 0.25s;

  &--disabledOverflow {
    overflow-x: unset;
  }

  &--small-padding {
    padding-top: 68px !important;
  }

  &--collapsed {
    padding-left: 68px;
  }

  @include media-tablet {
    padding-left: 0;
    padding-bottom: 58px;
    padding-top: 68px;
  }

  &--game-page {
    overflow-x: hidden;
    @include hide-scrollbar;

    @include media-tablet {
      padding-bottom: 58px;
      padding-top: 68px;
    }
  }

  &--disable-all {
    padding-left: unset;
    padding-top: unset;
  }

  &__footer {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--disabledContentStyle {
      padding-left: 24px;
      padding-right: 24px;

      @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  &__content {
    width: 100%;
    padding-top: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 24px;
    gap: 16px;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 16px;
    }

    &--disabledGap {
      gap: unset;
    }

    &--disabledContentStyle {
      all: unset;
      width: 100%;
      padding-top: 32px;
    }
  }
}
