@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 70px);
  gap: 16px;

  @include media-tablet {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__coin {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &--skeleton {
      @include skeleton();
      border-radius: 50%;
    }
  }
}
