@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 48px;

  @include media-tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__logo {
    width: 160px;
    opacity: 0.6;

    @include media-tablet {
      margin: 0 auto;
    }
  }

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--small {
      gap: 8px;
    }
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 65px 1fr;
    align-items: center;
    gap: 16px;

    @include media-tablet {
      grid-template-columns: 1fr;
    }
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    color: $text-secondary;

    &--left {
      text-align: left;

      @include media-tablet {
        text-align: center;
      }
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__image {
    width: 65px;
    margin: 0 auto;
  }
}
