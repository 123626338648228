@import "@/styles/theme/main.scss";
.container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 auto;

  &__step {
    width: 8px;
    height: 8px;
    border-radius: $border-radius;
    background-color: $text-secondary;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background-color: $text-primary;
    }

    &--active {
      width: 20px;
      background-color: $text-primary;
    }
  }

  @include media-tablet {
    margin-top: -16px;
  }

  &--absolute {
    position: absolute;
    z-index: 3;
    bottom: 24px;
    right: 24px;

    @include media-tablet {
      right: unset;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
    }
  }
}
