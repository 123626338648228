@import "@/styles/theme/main.scss";
.link {
  all: unset;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: $text-secondary !important;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $text-primary !important;
  }

  &--active {
    color: $primary-color !important;
  }
}
