@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-tablet {
    align-items: center;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: $text-primary;
    white-space: nowrap;
  }
}
