@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media-tablet {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }
}
