@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    cursor: grab;
    transition: all 0.25s ease-in-out;

    & > * {
      flex: 0 0
        calc(
          (100% - (var(--items-per-view) - 1) * 16px) / var(--items-per-view)
        );
    }

    @include hide-scrollbar;

    &--dragging {
      cursor: grabbing;
    }
  }

  &__mobile__only {
    display: none;

    @include media-tablet {
      display: flex;
    }
  }

  &__item {
    width: 100%;
    scroll-snap-align: center;
  }

  &__button {
    all: unset;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: $border-radius;
    cursor: pointer;
    z-index: 4;
    background-color: $card-color;
    color: $text-secondary;
    transition:
      background-color 0.25s ease-in-out,
      opacity 0.25s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;

    &--left {
      left: -32px;

      @include media-tablet {
        left: -12px;
      }
    }

    &--right {
      right: -32px;

      @include media-tablet {
        right: -12px;
      }
    }

    i {
      font-size: 14px;
    }

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    &:hover {
      background-color: $background-paper;
    }

    @include media-tablet {
      display: none;
    }
  }
}
