@import "@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 14px;

    & p {
      white-space: nowrap;
    }

    & i {
      color: $text-primary !important;
      font-size: 16px;
      transition: inherit;
    }

    &--custom-color {
      & i {
        color: var(--custom-color) !important;
      }

      & strong {
        color: var(--custom-color) !important;
      }
    }

    &--filter {
      display: flex;
      align-items: center;
      gap: 8px;

      @include media-phone {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &--disabled {
      pointer-events: none;
    }

    &:disabled {
      pointer-events: none !important;
      opacity: 0.8;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;

    &--hidden {
      @include media-phone {
        display: none;
      }
    }
  }

  &__button {
    all: unset;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    background-color: $background-paper;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    color: $text-secondary;
    border-radius: $border-radius;
    cursor: pointer;
    transition:
      background-color 0.25s ease-in-out,
      opacity 0.25s ease-in-out,
      filter 0.25s ease-in-out;

    &:hover {
      @include lighen(25%);
    }

    &:disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    &--secondary {
      background-color: $background-default;
    }
  }

  &__label-link {
    all: unset;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    background-color: $background-paper;
    height: 36px;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    color: $text-secondary !important;
    border-radius: $border-radius;
    gap: 8px;

    & i {
      font-size: 10px;
    }

    &:hover {
      background-color: $card-color;
      color: $text-primary !important;
    }
  }

  &__hide-mobile {
    display: flex;
    align-items: center;
    gap: 8px;

    @include media-phone {
      display: none;
    }
  }
}
